.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.7);
  z-index: 10;
}

.App-logo {
  height: 40px;
  pointer-events: none;
  margin-bottom: 50px;
}

.App-link {
  color: #61dafb;
}

.btn-save{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 10px;
  background: rgba(100,100,100,0.5);
  align-items: center;
  justify-content: center;
  display: flex;
}
