body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-image: url(https://www.stackhouse.it/wp-content/uploads/2019/01/devops3.jpg);
}

html, body{
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: white;
  display: block;
  text-decoration: none;
}

h1{
  font-size: 1.5rem;
}

.spacer{
  margin: 5px 0;
}